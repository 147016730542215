import * as React from 'react';
import { UseStyles } from '../lib/types/mui';
import { Theme, Grid, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { TWorkCardP, WorkCard } from './WorkCard';
import { TFeaturedWorkCardP, FeaturedWorkCard } from './FeaturedWorkCard';

type ClassKey = 'otherCardItem' | 'featuredCardGrid';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    featuredCardGrid: {
        marginBottom: theme.spacing(3), // As close to half of the `otherCardGrid` spacing as possible
    },
    otherCardItem: { display: 'flex' },
}));

export type TFeaturedProject = {
    pid: string;
    card: TFeaturedWorkCardP;
};
export type TProject = {
    pid: string;
    card: TWorkCardP;
};

export type TProjectsCardGridP = {
    featuredProjects?: TFeaturedProject[];
    projects?: TProject[];
};

export function ProjectsCardGrid(props: TReactFCP<TProjectsCardGridP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { featuredProjects, projects } = props;
    return (
        <div>
            {(featuredProjects ?? []).length > 0 && (
                <Grid className={styles.featuredCardGrid} container direction='column' wrap='nowrap' spacing={6}>
                    {featuredProjects.map((p: TFeaturedProject) => (
                        <Grid key={p.pid} item>
                            <FeaturedWorkCard {...p.card} />
                        </Grid>
                    ))}
                </Grid>
            )}
            {(projects ?? []).length > 0 && (
                <Grid container spacing={6} alignItems='stretch' >
                    {projects.map((p: TProject) => (
                        <Grid key={p.pid} className={styles.otherCardItem} item xs={12} sm={6} md={4} xl={3}>
                            <WorkCard {...p.card} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
}
