import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { Theme, Card, CardActionArea, CardContent, Chip, Grid, makeStyles } from '@material-ui/core';
import { TProjectWork } from '../data/projects';
import { Link } from './Link';
import { Typography } from './Typography';

type ClassKey = 'card' | 'contentGrid' | 'content' | 'header' | 'description' | 'stack' | 'categorization';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    card: { display: 'flex', flex: 1, },
    contentGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5, 6),
    },
    header: { marginBottom: theme.baseline.blockMarginBottom },
    description: { flex: 1 },
    stack: {
        ...theme.typography.sizing(1, 'mono'),
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.custom.card.labelArea.color,
        color: theme.palette.custom.pages.projects.previewCard.stack.color,
    },
    categorization: {
        color: theme.palette.custom.card.labelArea.contrastText.lighter,
        backgroundColor: theme.palette.custom.card.labelArea.color
    }
}));

export type TWorkCardP = Omit<TProjectWork, 'slug'> & {
    className?: string;
    slug?: string;
    disableLink?: boolean;
};

export function WorkCard(props: TReactFCP<TWorkCardP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { className, disableLink, slug, title, spoiler, description, stack, category, type }: TWorkCardP = props;
    const content: React.ReactNode = (
        <React.Fragment>
            <CardContent className={styles.content}>
                <div className={styles.header}>
                    <Typography variant='h4'>{title}</Typography>
                    <Typography variant='subtitle2' color='lighter' bold>{spoiler}</Typography>
                </div>
                <Typography className={styles.description} paragraph color='light'>{description}</Typography>
                <Grid container spacing={2} alignItems='center'>
                    {stack.map((s: string) => (
                        <Grid key={s} item>
                            <Chip className={styles.stack} label={s} variant='outlined' size='small' />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
            <Typography component='div' className={styles.categorization} variant='overline' align='center' noWrap>
                {category} &middot; {type}
            </Typography>
        </React.Fragment>
    );
    return (
        <Card className={clsx(styles.card, className)}>
            {disableLink && <div className={styles.contentGrid}>{content}</div>}
            {!disableLink && (
                <CardActionArea
                    className={styles.contentGrid}
                    component={Link}
                    variant='internal'
                    to={slug || '#'}
                    disableColor
                    disableUnderline
                >
                    {content}
                </CardActionArea>
            )}
        </Card>
    );
}
