import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { UseStyles } from '../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { TWorkCardP, WorkCard } from './WorkCard';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import { GatsbyImageWithIEPolyfillProps } from 'gatsby-image/withIEPolyfill';
import { useBreakpointWidth } from '../hooks/useWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

type TFeaturedImageProps = Omit<
    GatsbyImageWithIEPolyfillProps, 'objectFit' | 'objectPosition' | 'fixed' | 'resolutions'
>;

type StyleProps = { orientation: 'left' | 'right'; };

type ClassKey = 'container' | 'image' | 'card' | 'imageContainer';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    container(props: StyleProps) {
        return {
            display: 'flex',
            flexDirection: props.orientation === 'left' ? 'row' : 'row-reverse',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                alignItems: 'unset'
            }
        };
    },
    image: {
        minHeight: '200px',
        maxHeight: '300px',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center center',
        transition: theme.transitions.create(['height']),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    imageContainer(props: StyleProps) {
        return {
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            flex: '1 1 0%',
            marginLeft: props.orientation === 'left' ? '-20%' : 'initial',
            marginRight: props.orientation === 'right' ? '-20%' : 'initial',
            boxShadow: theme.shadows[3],
            height: '100%',
            transition: theme.transitions.create(['margin-left', 'margin-right', 'height']),
            [theme.breakpoints.down('sm')]: {
                marginLeft: props.orientation === 'left' ? '-10%' : 'initial',
                marginRight: props.orientation === 'right' ? '-10%' : 'initial',
            },
            [theme.breakpoints.down('xs')]: {
                flex: '1 1 0%',
                boxShadow: 'none',
                width: '100%',
                height: 'auto',
                marginLeft: props.orientation === 'left' ? '-100%' : 'initial',
                marginRight: props.orientation === 'right' ? '-100%' : 'initial',
                position: 'relative',
                // This is a screen filter over the image. I didn't use it because I'm worried about userbase coverage
                // of https://caniuse.com/#search=mix-blend-mode
                // '&:before': {
                //     borderRadius: theme.shape.borderRadius,
                //     content: '""',
                //     position: 'absolute',
                //     width: '100%',
                //     height: '100%',
                //     top: 0,
                //     left: 0,
                //     right: 0,
                //     bottom: 0,
                //     zIndex: 1,
                //     // transition: ${theme.transition},
                //     backgroundColor: 'hsl(210, 36%, 96%)',
                //     mixBlendMode: 'screen',
                // }
            }
        };
    },
    card: {
        flex: '1 2 0%',
        zIndex: 2,
        boxShadow: theme.shadows[12],
        transition: theme.transitions.create(['flex', 'opacity']),
        [theme.breakpoints.only('sm')]: {
            flex: '2 1 0%',
        },
        [theme.breakpoints.down('xs')]: {
            opacity: 0.92,
            flex: '1 2 0%',
        }
    },
    header: {},
}));

export type TFeaturedWorkCardP = TWorkCardP & {
    classes?: Partial<Pick<ClassNameMap<ClassKey>, 'container' | 'image'>>;
    featuredImage: TFeaturedImageProps;
    featuredImageGrayscale: TFeaturedImageProps;
    orientation?: 'left' | 'right';
};

export function FeaturedWorkCard(props: TReactFCP<TFeaturedWorkCardP>): TReactFCR {
    const {
        className, featuredImage, featuredImageGrayscale, orientation = 'left', ...workCardProps
    }: TFeaturedWorkCardP = props;
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ orientation });
    // Breakpoint width checking causes flicker when the width changes because 'new' image props are spread onto the
    // image component ... ugh.
    const width: Breakpoint = useBreakpointWidth();
    const imgProps: TFeaturedImageProps = width !== 'xs' ? props.featuredImage : props.featuredImageGrayscale;
    return (
        <div className={clsx(styles.container, className)}>
            <WorkCard className={styles.card} {...workCardProps} />
            <div className={styles.imageContainer}>
                <GatsbyImage
                    objectFit='cover'
                    objectPosition='center center'
                    className={styles.image}
                    style={{}}
                    // imgStyle={{ width: '100%', height: '100%' }}
                    {...imgProps}
                />
            </div>
        </div>
    );
}
