import * as React from 'react';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { SEO } from '../../components/app/SEO';
import { Page } from '../../components/app/Page';
import { Typography } from '../../components/Typography';
import { graphql } from 'gatsby';
import { GQLProjectsPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { TProjectWork } from '../../data/projects';
import { FluidObject } from 'gatsby-image';
import { ProjectsCardGrid } from '../../components/ProjectsCardGrid';

type ClassKey = 'main' | 'title' | 'otherCardItem' | 'featuredCardGrid' | 'featuredCardItem';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(6),
        maxWidth: theme.breakpoints.width('lg'),
        margin: '0 auto',
        width: '100%'
    },
    featuredCardGrid: {
        marginBottom: theme.spacing(3), // As close to half of the `otherCardGrid` spacing as possible
    },
    featuredCardItem: {},
    otherCardItem: { display: 'flex' },
    title: { marginBottom: theme.spacing(6) }
}));

type TFeaturedEdge = GQLProjectsPageQuery['featuredWorks']['edges'][0];
type TOtherEdge = GQLProjectsPageQuery['otherWorks']['edges'][0];

export type TProjectsP = {
    data: GQLProjectsPageQuery;
};

function Projects(props: TReactFCP<TProjectsP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsHero, featuredWorks, otherWorks }: GQLProjectsPageQuery = props.data;
    const slug: string = sitePage!.path!;
    return (
        <main className={styles.main}>
            <SEO
                slug={slug}
                title={'Projects'}
                seoTitle='Digital Craftsmanship'
                description={`
                    Deep dives into some of the projects John Richter has worked on personally and professionally.
                `}
                image={{
                    src: projectsHero!.childImageSharp!.original!.src!,
                    width: projectsHero!.childImageSharp!.original!.width!,
                    height: projectsHero!.childImageSharp!.original!.height!,
                }}
            />
            <div className={styles.title}>
                <Typography variant='h1' align='center'>Projects</Typography>
                <Typography variant='subtitle2' align='center' color='lighter'>
                    Things I've designed and built personally and professionally
                </Typography>
            </div>
            <ProjectsCardGrid
                featuredProjects={
                    featuredWorks.edges.map((e: TFeaturedEdge, index: number) => ({
                        pid: e.node.pid!,
                        card: {
                            // Must include trailing slash since the slugs we get from GQL don't have them
                            slug: `${slug}${e.node.slug!}/`,
                            category: e.node.category!,
                            type: e.node.type!,
                            title: e.node.title!,
                            spoiler: e.node.spoiler!,
                            description: e.node.description!,
                            stack: e.node.stack! as string[],
                            links: e.node.links! as TProjectWork['links'],
                            featuredImage: {
                                fluid: e.node.featuredImage!.childImageSharp!.fluid! as FluidObject
                            },
                            featuredImageGrayscale: {
                                fluid: e.node.featuredImageGrayscale!.childImageSharp!.fluid! as FluidObject
                            },
                            orientation: index % 2 === 0 ? 'left' : 'right'
                        }
                    }))
                }
                projects={
                    otherWorks.edges.map((e: TOtherEdge) => ({
                        pid: e.node.pid!,
                        card: {
                            // Must include trailing slash since the slugs we get from GQL don't have them
                            slug: `${slug}${e.node.slug!}/`,
                            category: e.node.category!,
                            type: e.node.type!,
                            title: e.node.title!,
                            spoiler: e.node.spoiler!,
                            description: e.node.description!,
                            stack: e.node.stack! as string[],
                            links: e.node.links! as TProjectWork['links']
                        }
                    }))
                }
            />
        </main>
    );
}

export default (props: TReactFCP<TProjectsP>): TReactFCR => <Page component={Projects} cprops={props} />;

export const query = graphql`
    query ProjectsPage {
        sitePage(internalComponentName: {eq: "ComponentProjects"}) {
            path
        }
        projectsHero: file(relativePath: {eq: "images/projects/projects-hero.jpg"}) {
            childImageSharp {
                original {
                    src
                    width
                    height
                }
            }
        }
        featuredWorks: allProjectsYaml(sort: {fields: isFeatured, order: DESC}, filter: {isFeatured: {eq: true}}) {
            edges {
                node {
                    pid
                    slug
                    title
                    category
                    type
                    spoiler
                    description
                    stack
                    links {
                        homepage
                        repository
                    }
                    isFeatured
                    featuredImage {
                        childImageSharp {
                            fluid(maxWidth: 768) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    featuredImageGrayscale: featuredImage {
                        childImageSharp {
                            fluid(maxWidth: 600, grayscale: true) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        otherWorks: allProjectsYaml(sort: {fields: isFeatured, order: DESC}, filter: {isFeatured: {eq: false}}) {
            edges {
                node {
                    pid
                    slug
                    title
                    category
                    type
                    spoiler
                    description
                    stack
                    links {
                        homepage
                        repository
                    }
                    isFeatured
                }
            }
        }
    }
`;
